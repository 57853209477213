/* eslint-disable react/no-array-index-key */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Images
import Calendar from 'img/icon/calendar.inline.svg'
import Eyeglasses from 'img/icon/eyeglasses.inline.svg'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/shared/Hero'
import BestBlogs from 'components/shared/BestBlogs'
import ButtonDefault from 'components/elements/ButtonDefault'
import ParseContent from 'components/shared/ParseContent'
import GravityForm from 'components/GravityForm'
import Customer from 'components/shared/Customer'

// Third Party
import parse from 'html-react-parser'
import styled from 'styled-components'

const BlogContent = styled.div`
  h3 {
    font-size: ${(props) => props.theme.font.size.xm};
    font-weight: ${(props) => props.theme.font.weight.xl};
    padding-top: 40px;
  }
`

const Story = styled(ParseContent)`
  p {
    font-size: ${(props) => props.theme.font.size.sm};
    color: ${(props) => props.theme.color.text.dark};
    line-height: 30px;
  }
`

const ContactLink = styled.div``

const PostTemplate = ({
  data: {
    page: { title, path, acf, categories, yoast_meta: yoast },
  },
}) => {
  return (
    <Layout>
      {yoast && (
        <SEO
          yoast={yoast}
          path={path}
          image={{
            url: acf.preview.image.localFile
              ? acf.preview.image.localFile.childImageSharp.openGraph.src
              : acf.content.image.localFile.childImageSharp.openGraph.src,
          }}
        />
      )}

      <Hero
        className="mb-lg-3"
        article
        backUrl="/kennis/"
        title={parse(title)}
        content={acf.content}
      />

      <div className="container pb-5">
        <div className="row">
          <BlogContent
            className={`${acf.relation ? 'col-md-8' : 'col-lg-7'} pr-lg-5 `}
          >
            <div className="d-flex">
              {categories?.map((category, index) => (
                <p
                  key={index}
                  className="mb-1 color-secondary mr-3 font-weight-xl font-size-sm"
                >
                  {category.name}
                </p>
              ))}
            </div>
            <div className="d-flex pb-5 align-items-center">
              <p className="mb-0 mr-3 font-size-sm color-soft">
                <Calendar className="mr-1" />
                {acf.preview.date}
              </p>
              <p className="mb-0 font-size-sm color-soft">
                <Eyeglasses className="mr-1" />
                {acf.preview.readtime}
              </p>
            </div>
            <Story content={acf.content.story} className="pb-5" />
            {acf.content.gravity_form &&
              acf.content.gravity_form.gravity_form_id !== 0 && (
                <>
                  <Story content={acf.content.gravity_form.title} />
                  <div className="px-3 px-lg-0">
                    <GravityForm
                      id={acf.content.gravity_form.gravity_form_id}
                      brochure
                      className="py-3 mb-5"
                    />
                  </div>
                </>
              )}
            <ContactLink className="py-4 d-flex flex-wrap justify-content-center align-items-center">
              <p className="mb-md-0 mb-3 mr-lg-4 text-center font-size-xm font-weight-xl">
                Meer weten over dit onderwerp?
              </p>
              <ButtonDefault icon="arrow" to="/contact">
                <p>Neem contact op</p>
              </ButtonDefault>
            </ContactLink>
          </BlogContent>
          {acf.relation &&
          acf.relation.length > 0 &&
          acf.relation[0].wordpress_id !== 496 ? (
            <div className="col-md-4 px-lg-5 mt-lg-5 pt-5">
              <p className="color-secondary font-size-sm font-weight-xl mb-0 mt-3 mb-5 pb-5">
                ClearMind Regisseurs
              </p>
              <Customer content={acf.relation[0]} />
              {acf.relation[1] ? <Customer content={acf.relation[1]} /> : <p />}
              {acf.relation[2] ? <Customer content={acf.relation[2]} /> : <p />}
            </div>
          ) : (
            <div
              className={`${acf.relation ? 'col-lg-4' : 'col-lg-5'} pt-lg-5`}
            >
              <BestBlogs />
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressPost(wordpress_id: { eq: $wordpress_id }) {
      ...PostsFrag
    }
  }
`
